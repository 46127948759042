<script>
import Vue from "vue";
import GfApiServicePlugin from "@gf/gf-api-services"
import constants from "./constants"

export default {
  components: {
    GfApiServicePlugin
  },
  props:{
    baseDomain: {
      type: String,
      default: "gofundraise.com.au"
    },
     apiDomain: {
      type: String,
      default: "api.gofundraise.com"
    },
  },
  mounted() {
    Vue.use(GfApiServicePlugin, {logger: this.$gfLogger, apiDomain: this.apiDomain });
    this.$gfLogger.logInfo("Mounted Configuration Loader Mixin");

  },

  methods: {
    async $_ConfigurationLoaderMixin_GetCookieConsentSettings(regionId, eventId) {
      this.$gfLogger.logInfo("$_ConfigurationLoaderMixin_GetCookieConsentSettings()", {regionId: regionId, eventId: eventId});
      return await this.$gfApiServices.configurationService.getSettings({
        SettingsContext: {
          Region: regionId,
          EventId: eventId
        },
        Types: [
          {
            ConfigurationName: constants.cookieConfigurationType,
            ConfigurationSubType: constants.cookieConfigurationSubType
          }
        ]
      });
    }
  }
}
</script>

