<template lang="html">
  <div
    v-show="displayConsent"
    id="vue-gf-privacy-consent"
    class="container"
  >
    <cookie-consent
      :cookie-name="cookieName"
      :style="{'background-color': backgroundColour}"
      :override-consent="overrideConsent"
      @showConsentBanner="val => show = val"
    >
      <template
        slot="message"
      >
        <span
          style="margin-left: 1em; margin-right: 1em"
          v-html="bannerMessage"
        />
      </template>
      <template
        slot="button"
      >
        <span v-html="acceptDescription" />
      </template>
    </cookie-consent>
  </div>
</template>

<script>
import Vue from "vue";
import { VueLoggerPlugin } from "@gf/gf-logger"
import templates from './consentTemplates'
import CookieConsent from './components/CookieConsent.vue'
import ConfigurationLoaderMixinVue from './ConfigurationLoaderMixin.vue';
import constants from './constants';

Vue.use(VueLoggerPlugin);

export default {
  name: 'GfPrivacyConsent',

  components: {
    CookieConsent
  },

  mixins: [ConfigurationLoaderMixinVue],

  props:  {
    demo: {
      type: Boolean,
      default: false
    },
    demoConfiguration: {
      type: Object,
      default: null
    },



    eventCampaignId: {
      type: Number,
      default: null
    },

    regionId: {
      type: String,
      default: null
    },

    beneficiaryId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      displayConsent: false,
      bannerMessage: templates.default.bannerMessage,
      acceptDescription: templates.default.acceptButton,
      backgroundColour: templates.default.backgroundColour,
      cookieName:"gf_cookie_consent",
      settings: null,
      show: false,
      overrideConsent: false
    }
  },
    watch: {
        show(val) {
            if (val) {
                this.loadCookieSettings()
            }
        }
    },

  async mounted() {
    this.$gfLogger.logInfo("Mounted GfPrivacyConsent");
    this.$gfLogger.logInfo(this.baseDomain);
  },

  methods: {
    async loadCookieSettings() {
      var settings;
      if (this.demo)
        settings = this.demoConfiguration
      else
        settings = await this.$_ConfigurationLoaderMixin_GetCookieConsentSettings(this.regionId, this.eventCampaignId);

      this.$gfLogger.logInfo("Got entity configuration settings", settings);

      if (settings && settings.Settings && settings.Settings.length > 0) {
        var firstMatchedSettings = settings.Settings
          .find(el => el.ConfigurationName == constants.cookieConfigurationType && el.ConfigurationSubType == constants.cookieConfigurationSubType);
        if (firstMatchedSettings) {
          this.settings = firstMatchedSettings.Settings;
          this.acceptDescription = this.settings.AcceptDescription;
          this.bannerMessage = this.settings.Description;
          this.backgroundColour = this.settings.BackgroundColour;
        }
        if (this.settings.Categories && this.settings.Categories.length > 0)
          this.displayConsent = true;
        else
          this.overrideConsent = true;
      } else {
        this.overrideConsent = true;
      }

      this.$gfLogger.logInfo("Got cookie settings", this.settings);
    }
  }
}
</script>

<style lang="scss">
  @import "scss/cookie-consent";
  @import "scss/cookie-consent-bottom";
  @import "scss/cookie-consent-transition";
</style>
